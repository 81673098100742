//
// Custom radio
//

.custom-radio {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
    height: auto;
  }
}
