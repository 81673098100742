//
// Alert
//

.alert {
  font-size: $font-size-sm;
}

// Alert heading

.alert-heading {
  font-weight: $font-weight-bold;
  font-size: $h4-font-size;
  margin-top: 0.15rem;
}

// Alert icon

.alert-icon {
  font-size: 1.25rem;
  margin-right: 1.25rem;
  display: inline-block;
  vertical-align: middle;

  i.ni {
    position: relative;
    top: 2px;
  }
}

// Alert text next to an alert icon

.alert-text {
  display: inline-block;
  vertical-align: middle;
}

// Alert links

[class*="alert-"] {
  .alert-link {
    color: $white;
    border-bottom: 1px dotted rgba($white, 0.5);
  }
}

.notification {
  display: inline-block !important;
  clear: both !important;
  position: relative !important;
  font-family: "Lato", Tahoma, Arial !important;
  font-size: 14px !important;
  padding: 8px 45px 9px 15px !important;
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  width: 100% !important;
  pointer-events: all !important;
  cursor: default !important;
  transform: translateX(0) !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  min-height: 54px !important;
  border-top: none !important;
  padding: 0px !important;
  margin: 0px;
  height: 100%;
  border-radius: 25px !important;
}

.notification::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
